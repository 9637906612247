import React, { useState, useEffect } from "react";
import { useNavigate, Route, Routes, useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';

//MUI
import { useMediaQuery } from "@mui/material";

//Redux
import { setSubscriptionExists, resetSubscription } from '../../features/subscriptions/subscriptionSlice';

//API
import { fetchCurrentUserInfos } from "../../api/userAPI";
import { fetchSubscription } from "../../api/subsciptionAPI"
import { fetchParticipantByUserId } from "../../api/groupOrdersParticipantsAPI"

// Components
import ClientOrdersList from "../../components/client-dashboard/ClientOrdersList";
import ClientNavbar from "../../components/client-dashboard/ClientNavbar";
import ClientSubscription from "../../components/client-dashboard/ClientSubscription";
import Recipes from "../admin-dashboard/recipes/Recipes";
import MenuList from "../admin-dashboard/menu-maker/MenuList";
import MobileMenuList from "../../components/admin-dashboard/menus/MobileMenuList";
import MenuMaker from "../admin-dashboard/menu-maker/menu/MenuMaker";
import MobileOrderList from "../../components/client-dashboard/MobileOrderList";
import MobileRecipeList from "../../components/admin-dashboard/recipes/mobile/MobileRecipeList";
import GroupOrdersTracking from "./GroupOrdersTracking";
import MobileProduct from "../../components/admin-dashboard/stock/MobileProduct";
import Stock from "../admin-dashboard/stock/Stock";

// Styles
import "../admin-dashboard/AdminDashboard.css";
import ClientProfil from "../../components/client-dashboard/ClientProfil";

function DashboardClient() {

    const isTablet = useMediaQuery("(min-width: 601px) and (max-width: 1024px)");

    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { subscriptionExists, subscriptionInfos } = useSelector(state => state.subscription);


    const [userInfo, setUserInfo] = useState({});
    const [unpaidOrdersExist, setUnpaidOrdersExist] = useState(false);
    const [unpaidOrderNum, setUnpaidOrderNum] = useState(null);
    const [readyOrderExist, setReadyOrderExist] = useState(false);
    const [readyOrderNum, setReadyOrderNum] = useState(null);
    const [selectedSection, setSelectedSection] = useState("users");
    const [groupOrderexist, setGroupOrderExist] = useState(false);

    useEffect(() => {
        const fetchUserInfo = async () => {
            try {
                const data = await fetchCurrentUserInfos();
                setUserInfo(data);
            } catch (error) {
                console.error(
                    "Erreur lors de la récupération des informations de l'utilisateur:",
                    error
                );
            }
        };

        fetchUserInfo();
    }, []);

    useEffect(() => {
        const fetchParticipant = async () => {
            try {
                const userId = userInfo.id;
                const isParticipant = await fetchParticipantByUserId(userId);
                if (isParticipant) {
                    setGroupOrderExist(true);
                } else {
                    console.error("This user doesn't participate at any group orders");
                }
            } catch (error) {
                console.error(
                    "Erreur lors de la récupération des informations de l'utilisateur:",
                    error
                );
            }
        };

        fetchParticipant();
    }, [userInfo]);

    useEffect(() => {
        const fetchUserSubscription = async () => {
            if (userInfo && userInfo.id) {  // Assurez-vous que userInfo.id est disponible
                try {
                    const userId = userInfo.id;
                    const subscriptionData = await fetchSubscription(userId);
                    console.log('subscriptionData', subscriptionData);
                    if (subscriptionData && subscriptionData.active) {
                        dispatch(setSubscriptionExists({
                            exists: true,
                            subscriptionInfos: subscriptionData,
                            active: subscriptionData.active
                        }));
                    } else {
                        dispatch(resetSubscription());
                    }
                } catch (error) {
                    console.error("Erreur lors de la récupération de l'abonnement:", error);
                    dispatch(resetSubscription());
                }
            }
        };

        if (userInfo.id) {
            fetchUserSubscription();
        }
    }, [userInfo, dispatch]);


    const renderSection = (userInfo) => {
        console.log('setUnpaidOrdersExist', setUnpaidOrdersExist)
        switch (selectedSection) {
            case "orders":
                return isTablet ? (
                    <MobileOrderList
                        setUnpaidOrdersExist={setUnpaidOrdersExist}
                        setUnpaidOrderNum={setUnpaidOrderNum}
                        setReadyOrderExist={setReadyOrderExist}
                        setReadyOrderNum={setReadyOrderNum}
                    />
                ) : (
                    <ClientOrdersList
                        setUnpaidOrdersExist={setUnpaidOrdersExist}
                        setUnpaidOrderNum={setUnpaidOrderNum}
                        setReadyOrderExist={setReadyOrderExist}
                        setReadyOrderNum={setReadyOrderNum}
                    />
                );
            case "group-orders":
                return isTablet && groupOrderexist ? (
                    <GroupOrdersTracking />
                ) : (
                    <GroupOrdersTracking />
                );
            case "profil":
                return <ClientProfil userInfo={userInfo} />;
            case "subscriptions":
                return subscriptionExists && subscriptionInfos.active ? (
                  console.log("hello This was a deleted file")
                ) : (
                    <ClientSubscription userInfo={userInfo} />
                );
            case "stocks":
                return subscriptionExists && subscriptionInfos.subscription_type === "pro_chef" ? (
                    <Stock userInfo={userInfo} />
                ) : (
                    <MobileProduct
                        userInfo={userInfo}
                        subscriptionInfos={subscriptionInfos}
                    />

                );
            case "recipes":
                if (subscriptionExists && subscriptionInfos.active) {
                    return isTablet ? (
                        <MobileRecipeList />
                    ) : (
                        <Recipes />
                    );
                } else {
                    return <div>Accès réservé aux abonnés actifs. Veuillez souscrire à un abonnement pour accéder aux recettes.</div>;
                }
            case "menulist":
                if (subscriptionExists && subscriptionInfos.active) {
                    return isTablet ? (
                        <MobileMenuList />
                    ) : (
                        <MenuList />
                    );
                } else {
                    return <div>Accès réservé aux abonnés actifs. Veuillez souscrire à un abonnement pour accéder aux menus.</div>;
                }
            case "menumaker":
                if (subscriptionExists && subscriptionInfos.active) {
                    return <MenuMaker menuId={id} />;
                } else {
                    return <div>Accès réservé aux abonnés actifs. Veuillez souscrire à un abonnement pour accéder aux menus.</div>;
                }
            default:
                return <ClientProfil userInfo={userInfo} />;
        }
    };

    return (
        <section className="admin-panel">
            <ClientNavbar onSelectSection={(section) => setSelectedSection(section)} />
            <section className="admin-dashboard container">
                {renderSection(userInfo)}
            </section>
        </section>
    );
}

export default DashboardClient;
