import { Routes, Route } from "react-router-dom";
import { useMediaQuery } from "@mui/material";

//Delivery Zones
import DeliveryZones from "../views/delivery-zones/DeliveryZones";

//Home 
import Home from "../views/home/Home";

//Auth
import Login from "../views/login/Login";
import MailResetPassword from "../views/reset-password/MailResetPassword";
import ResetPassword from "../views/reset-password/ResetPassword";
import Signup from "../views/signup/Signup";

//Client Dashboard
import DashboardClient from "../views/client-dashboard/DashboardClient";
import ClientMobile from "../views/client-dashboard/ClientMobile";
import ClientProfil from "../components/client-dashboard/ClientProfil";
import MobileOrderList from "../components/client-dashboard/MobileOrderList";
import ClientSubscription from "../components/client-dashboard/ClientSubscription";
import GroupOrdersTracking from "../views/client-dashboard/GroupOrdersTracking";

//Admin Dashboard
import AdminDashboard from "../views/admin-dashboard/AdminDashboard";
import AdminMobile from "../views/admin-dashboard/AdminMobile";
import MobileAllUsers from "../components/admin-dashboard/user-list/MobileAllUsers";
import AllOrders from "../views/admin-dashboard/orders/AllOrders";
import MobileAllOrders from "../components/admin-dashboard/order-list/MobileAllOrders"
import AllUsers from "../views/admin-dashboard/user-list/AllUsers"
import Stock from "../views/admin-dashboard/stock/Stock";
import MobileProduct from "../components/admin-dashboard/stock/MobileProduct"
import Recipes from "../views/admin-dashboard/recipes/Recipes";
import MobileRecipeList from "../components/admin-dashboard/recipes/mobile/MobileRecipeList";
import RecipesInfos from "../views/admin-dashboard/recipes/recipe-infos/RecipesInfos";
import SharedRecipe from "../views/admin-dashboard/recipes/recipe-infos/SharedRecipe";
import GroupOrders from "../views/admin-dashboard/group-orders/GroupOrders";

//MenuMaker
import MenuList from '../views/admin-dashboard/menu-maker/MenuList'
import MenuMaker from "../views/admin-dashboard/menu-maker/menu/MenuMaker";
import MobileMenuList from "../components/admin-dashboard/menus/MobileMenuList";
import ShoppingList from "../components/admin-dashboard/menus/ShoppingList";
import MobileShoppingList from "../components/admin-dashboard/menus/MobileShoppingList";
import SharedMenu from "../views/admin-dashboard/menu-maker/menu/SharedMenu";
import PricingPage from "../views/suscriptions/PricingPage";


//Orders and Payments
import OrderValidation from "../views/order-validation/OrderValidation";
import Checkout from "../views/checkout/Checkout";
import Paygreen from "../views/paygreen/Paygreen"

//Error
import Error from "../views/error/Error"

//Private Route
import PrivateRoute from "./PrivateRoute";

function AppRoutes() {

  const isMobile = useMediaQuery("(max-width: 600px)");
  const isTablet = useMediaQuery("(min-width: 601px) and (max-width: 1024px)");




  return (
    <Routes>
      <Route path="/" element={<DeliveryZones />} />
      <Route path="/home" element={<Home />} />
      <Route path="/error" element={<Error />} />
      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/reset-password" element={<MailResetPassword />} />
      <Route path="/reset-password/:id/:token" element={<ResetPassword />} />
      <Route
        path="/account"
        element={
          isMobile ? (
            <PrivateRoute rolesRequired={["client"]} fallbackComponent={AdminMobile}>
              <ClientMobile />
            </PrivateRoute>
          ) : (
            <PrivateRoute rolesRequired={["client"]} fallbackComponent={AdminDashboard}>
              <DashboardClient />
            </PrivateRoute>
          )
        }
      />
      <Route
        path="/account/my-orders"
        element={
          isMobile || isTablet ? (
            <PrivateRoute rolesRequired={["client"]} fallbackComponent={AdminMobile}>
              <MobileOrderList />
            </PrivateRoute>
          ) : (
            <PrivateRoute rolesRequired={["client"]} fallbackComponent={AdminDashboard}>
              <DashboardClient />
            </PrivateRoute>
          )
        }
      />
      <Route
        path="/account/group-orders"
        element={
          isMobile || isTablet ? (
            <PrivateRoute rolesRequired={["admin"]} fallbackComponent={AdminMobile}>
              <GroupOrders />
            </PrivateRoute>
          ) : (
            <PrivateRoute rolesRequired={["admin"]} fallbackComponent={AdminDashboard}>
              <GroupOrders />
            </PrivateRoute>
          )
        }
      />
      <Route
        path="/account/orders-tracking"
        element={
          isMobile || isTablet ? (
            <PrivateRoute rolesRequired={["client"]}>
              <GroupOrdersTracking />
            </PrivateRoute>
          ) : (
            <PrivateRoute rolesRequired={["client"]}>
              <GroupOrdersTracking />
            </PrivateRoute>
          )
        }
      />
      <Route
        path="/account/subscriptions"
        element={
          isMobile ? (
            <PrivateRoute rolesRequired={["client"]} fallbackComponent={AdminMobile}>
              <ClientSubscription />
            </PrivateRoute>
          ) : (
            <PrivateRoute rolesRequired={["client"]} fallbackComponent={AdminDashboard}>
              <DashboardClient />
            </PrivateRoute>
          )
        }
      />
      <Route
        path="/account/profil"
        element={
          <PrivateRoute>
            {isMobile ? <ClientProfil /> : <DashboardClient section="profil" />}
          </PrivateRoute>
        }
      />
      <Route
        path="/account/users-list"
        rolesRequired={["admin"]}
        element={
          isMobile ? (
            <PrivateRoute>
              <MobileAllUsers />
            </PrivateRoute>
          ) : (
            <PrivateRoute>
              <AllUsers />
            </PrivateRoute>
          )
        }
      />
      <Route
        path="/account/orders-list"
        rolesRequired={["admin"]}
        element={
          isMobile ? (
            <PrivateRoute>
              <MobileAllOrders />
            </PrivateRoute>
          ) : (
            <PrivateRoute>
              <AllOrders />
            </PrivateRoute>
          )
        }
      />

      <Route
        path="/account/stock"
        rolesRequired={["admin"]}
        element={
          isMobile ? (
            <PrivateRoute>
              <MobileProduct />
            </PrivateRoute>
          ) : (
            <PrivateRoute>
              <Stock />
            </PrivateRoute>
          )
        }
      />

      <Route
        path="/recipes"
        rolesRequired={["admin"]}
        element={
          isMobile ? (
            <PrivateRoute>
              <MobileRecipeList />
            </PrivateRoute>
          ) : (
            <PrivateRoute>
              <Recipes />
            </PrivateRoute>
          )
        }
      />
      <Route
        path="/recipes/:id"
        rolesRequired={["admin"]}
        element={
          <PrivateRoute>
            <RecipesInfos />
          </PrivateRoute>
        }
      />

      <Route
        path="/account/menulist"
        rolesRequired={["admin"]}
        element={
          isMobile ? (
            <PrivateRoute>
              <MobileMenuList />
            </PrivateRoute>
          ) : (
            <PrivateRoute>
              <MenuList />
            </PrivateRoute>
          )
        }
      />

      <Route
        path="/account/menumaker/:id"
        rolesRequired={["admin"]}
        element={
          <PrivateRoute>
            <MenuMaker />
          </PrivateRoute>
        }
      />

      <Route
        path="/menu/:id/shared"
        element={

          <SharedMenu />

        }
      />

      <Route
        path="/account/menumaker/:id/shopping-list"
        rolesRequired={["admin"]}
        element={
          <PrivateRoute>
            <ShoppingList />
          </PrivateRoute>
        }
      />

      <Route
        path="/account/menumaker/pricing"
        rolesRequired={["admin"]}
        element={
          <PrivateRoute>
            <PricingPage />
          </PrivateRoute>
        }
      />

      <Route
        path="/recipes/:id/shared"
        element={

          <SharedRecipe />

        }
      />
      <Route path="/order-validation" element={<OrderValidation />} />
      <Route path="/paygreen" element={<Paygreen />} />
      <Route
        path="/checkout"
        rolesRequired={["client", "admin"]}
        element={
          <PrivateRoute>
            <Checkout />
          </PrivateRoute>
        }
      />

    </Routes>
  );
}

export default AppRoutes;
