import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { setSubscriptionExists, resetSubscription } from '../../features/subscriptions/subscriptionSlice';
import { fetchCurrentUserInfos, fetchCurrentUserOrders } from "../../api/userAPI";
import { fetchSubscription } from "../../api/subsciptionAPI";
import { fetchParticipantByUserId } from "../../api/groupOrdersParticipantsAPI";
import Typography from '@mui/joy/Typography';
import Button from '@mui/joy/Button';
import PopupFridge from "../../components/elements/popup/popup-fridge/PopupFridge";
import "../admin-dashboard/AdminDashboard.css";

function ClientMobile() {
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const subscriptionExists = useSelector((state) => state.subscription.subscriptionExists);
    const subscriptionInfos = useSelector((state) => state.subscription.subscriptionInfos);

    const [userInfo, setUserInfo] = useState({});
    const [unpaidOrdersExist, setUnpaidOrdersExist] = useState(false);
    const [unpaidOrderNum, setUnpaidOrderNum] = useState(null);
    const [readyOrderExist, setReadyOrderExist] = useState(false);
    const [showPopupFridge, setShowPopupFridge] = useState(false);
    const [readyOrderNum, setReadyOrderNum] = useState(null);
    const [groupOrderExist, setGroupOrderExist] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [orderError, setOrderError] = useState(null);
    const [subscriptionResponse, setSubscriptionResponse] = useState(null);

    // Fetch user info
    useEffect(() => {
        const fetchUserInfo = async () => {
            try {
                const data = await fetchCurrentUserInfos();
                setUserInfo(data);
            } catch (error) {
                console.error("Erreur lors de la récupération des informations de l'utilisateur:", error);
            } finally {
                setIsLoading(false); 
            }
        };

        fetchUserInfo();
    }, []);

    // Fetch orders and participant info
    useEffect(() => {
        const fetchUserOrderAndParticipant = async () => {
            if (userInfo.id) {
                try {
                    const data = await fetchCurrentUserOrders();
                    const userId = userInfo.id;
                    const isParticipant = await fetchParticipantByUserId(userId);

                    if (data && data.length > 0) {
                        const lastOrder = data[0];

                        // Set unpaid and ready orders
                        setUnpaidOrdersExist(lastOrder.payment_status === false);
                        setUnpaidOrderNum(lastOrder.order_num);

                        if (lastOrder.status === "accepted" && (!isParticipant || lastOrder.id !== isParticipant.order_id)) {
                            setReadyOrderExist(true);
                            setReadyOrderNum(lastOrder.order_num);
                        } else {
                            setReadyOrderExist(false);
                        }

                        // Group order visibility
                        if (isParticipant && lastOrder.id === isParticipant.order_id && lastOrder.payment_status !== "paid") {
                            setGroupOrderExist(true);
                        } else {
                            setGroupOrderExist(false);
                        }
                    } else {
                        setUnpaidOrdersExist(false);
                        setReadyOrderExist(false);
                        setGroupOrderExist(false);
                    }
                } catch (error) {
                    console.error("Erreur lors de la récupération des commandes et des informations du participant :", error);
                    setOrderError("Impossible de charger vos commandes. Veuillez réessayer plus tard.");
                }
            }
        };

        if (userInfo.id) {
            fetchUserOrderAndParticipant();
        }
    }, [userInfo.id]);

    // Fetch user subscription
    useEffect(() => {
        const fetchUserSubscription = async () => {
            if (userInfo.id) {
                try {
                    const subscriptionData = await fetchSubscription(userInfo.id);
                    setSubscriptionResponse(subscriptionData);
                    if (subscriptionData) {
                        dispatch(setSubscriptionExists({
                            exists: true,
                            subscriptionInfos: subscriptionData,
                            active: subscriptionData.active
                        }));
                    } else {
                        dispatch(resetSubscription());
                    }
                } catch (error) {
                    console.error("Erreur lors de la récupération de l'abonnement:", error);
                    setSubscriptionResponse({ status: 500, message: "Erreur lors de la récupération de l'abonnement." });
                }
            }
        };

        if (userInfo.id) {
            fetchUserSubscription();
        }
    }, [userInfo, dispatch]);

    const handleUserClick = () => {
        navigate("/account/profil");
    };

    const handleOrderClick = () => {
        navigate("/account/my-orders");
    };

    const handleGroupOrderClick = () => {
        navigate("/account/orders-tracking");
    };

    const handleSubscriptionClick = () => {
        navigate("/account/subscriptions");
    };

    const handleRecipeClick = () => {
        navigate("/recipes");
    };

    const handleMenuMakerClick = () => {
        navigate("/account/menulist");
    };

    const handleStockClick = () => {
        navigate("/account/stock");
    };

    const handleButtonClick = () => {
        if (unpaidOrdersExist && unpaidOrderNum) {
            localStorage.setItem("order_num", unpaidOrderNum);
            navigate("/checkout");
        } else if (readyOrderExist) {
            setShowPopupFridge(true);
        } else {
            navigate("/home");
        }
    };

    const handleActionSuccess = () => {
        setReadyOrderExist(false);
    };

    // Gérer l'état de chargement ou les erreurs
    if (isLoading) {
        return <div className="loading-screen"><h2>Chargement...</h2></div>;
    }

    if (orderError) {
        return <div className="error-message">{orderError}</div>;
    }

    // Vérifie si l'utilisateur a un abonnement
    if (subscriptionResponse?.status === 200 && !subscriptionResponse.data) {
        return (
            <section className='client-subscription'>
                <div className='flex-center'>
                    <h2>Aucun abonnement trouvé. Vous pouvez choisir une formule ci-dessous.</h2>
                </div>
                <div className='stripe-pricing'>
                    <stripe-pricing-table
                        pricing-table-id="prctbl_1PGLNoLn6islyFl10iHKSc58"
                        publishable-key="pk_live_E27q31wJqPtkHNEoQzSJya8200AoUbm2JJ"
                        client-reference-id={userInfo.id}
                        customer-email={userInfo.email}
                    />
                </div>
                <div className="flex-center">
                    <Button sx={buttonStyle} variant="outlined" size="lg" onClick={handleUserClick}>
                        Retour
                    </Button>
                </div>
            </section>
        );
    }

    if (subscriptionResponse?.status === 200 && subscriptionResponse.data) {
        const subscriptionInfos = subscriptionResponse.data;

        return (
            <section className='client-manage-subscription'>
                <div className='flex-center'>
                    <h2 className='client-manage-subscription-title'>Votre abonnement Menu Maker</h2>
                </div>
                <div className='flex-center'>
                    <p className='client-manage-subscription-description'>
                        Vous êtes membre premium Mamossa depuis le {new Date(subscriptionInfos.createdAt).toLocaleDateString()}. 
                        Vous souscrivez à l'abonnement {subscriptionInfos.subscription_type === "menu_maker" ? "Menu Maker" : subscriptionInfos.subscription_type}.
                    </p>
                </div>
                <div className='flex-center'>
                    <a href="https://billing.stripe.com/p/login/14k18a8FB82f0wM6oo" target="_blank" rel="noopener noreferrer">
                        <button className='btn-full'>Gérer mon abonnement</button>
                    </a>
                </div>
            </section>
        );
    }

    // Afficher les boutons par défaut si tout est bien défini
    return (
        <>
            <div className="admin-mobile-header">
                <h2>Hello {userInfo.firstname} 👋</h2>
                <Typography level="body-ms" textColor={"#f5f5f5"} paddingLeft={'1rem'}>Que voulez-vous faire aujourd'hui?</Typography>
            </div>
            <div className="admin-mobile-actions">
                <Button sx={buttonStyle} variant="outlined" size="lg" onClick={handleUserClick}>
                    Mon Profil
                </Button>
                <Button sx={buttonStyle} variant="outlined" size="lg" onClick={handleOrderClick}>
                    Mes Commandes
                </Button>
                <Button sx={buttonStyle} variant="outlined" size="lg" onClick={handleGroupOrderClick}>
                    Suivi de commandes
                </Button>
                <Button sx={buttonStyle} variant="outlined" size="lg" onClick={handleSubscriptionClick}>
                    Mes Abonnements
                </Button>
                {subscriptionExists && subscriptionInfos?.active && subscriptionInfos.subscription_type === "pro_chef" && (
                    <>
                        <Button sx={buttonStyle} variant="outlined" size="lg" onClick={handleStockClick}>
                            Les Produits
                        </Button>
                    </>
                )}
                {subscriptionExists && subscriptionInfos?.active && (
                    <>
                        <Button sx={buttonStyle} variant="outlined" size="lg" onClick={handleRecipeClick}>
                            Les Recettes
                        </Button>
                        <Button sx={buttonStyle} variant="outlined" size="lg" onClick={handleMenuMakerClick}>
                            Menu Maker
                        </Button>
                    </>
                )}
                <Button color="danger" sx={{ width: "270px", height: '50px', marginBottom: "3rem" }} variant="outlined" size="lg"
                    onClick={handleButtonClick}>
                    {unpaidOrdersExist
                        ? "Payer la commande"
                        : readyOrderExist
                            ? "Ouvrir le frigo"
                            : "Commander un repas"}
                </Button>
            </div>
            {showPopupFridge && (
                <PopupFridge
                    isOpen={showPopupFridge}
                    onConfirm={() => setShowPopupFridge(false)}
                    onActionSuccess={handleActionSuccess}
                    readyOrderNum={readyOrderNum}
                />
            )}
        </>
    );
}

export default ClientMobile;

const buttonStyle = {
    borderColor: "#006d26",
    color: "#006d26",
    width: "270px",
    height: '50px'
};
