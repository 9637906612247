import { useState, useEffect, useMemo } from "react";
import { Helmet } from 'react-helmet';

// Components
import RecipeHeader from "./RecipeHeader";
import RecipeImage from "./RecipeImage";
import RecipeNameForm from "./RecipeNameForm";
import RecipeDetailsContainer from "./RecipeDetailsContainer";
import toast from "react-hot-toast";

// API
import { fetchRecipeById, updateRecipeById, updateRecipeImage } from "../../../api/recipesAPI";

const partIdLabels = {
    1: "1 — Entrées",
    2: "2 — Plats",
    3: "3 — Desserts",
    4: "4 — Boissons",
};

function RecipesDetails({ recipeId, pictures, nutriFacts }) {
    // Local States
    const [recipe, setRecipe] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [editedFields, setEditedFields] = useState({
        name: "",
        price: "",
        barcode: "",
        partId: "1", // Valeur par défaut
        visibility: false,
        description: "",
        steps: "",
        prep_time: 0, // Valeur par défaut
    });

    // Fetch recipe data from API when the component mounts
    useEffect(() => {
        const getRecipeData = async () => {
            try {
                const recipeData = await fetchRecipeById(recipeId);
                console.log("Fetched recipe data:", recipeData); // Log the fetched recipe data
                setRecipe(recipeData);

                // Initialize editedFields with fetched recipe data
                if (recipeData) {
                    setEditedFields({
                        name: recipeData.name || "",
                        price: recipeData.price ? recipeData.price.toString() : "",
                        barcode: recipeData.barcode || "",
                        partId: recipeData.partId ? recipeData.partId.toString() : "1", // Valeur par défaut
                        visibility: recipeData.visibility !== undefined ? recipeData.visibility : false,
                        description: recipeData.description || "",
                        steps: recipeData.steps || "",
                        prep_time: recipeData.prep_time || 0, // Valeur par défaut
                    });
                }
            } catch (error) {
                console.error("Erreur lors de la récupération de la recette :", error);
            }
        };

        getRecipeData();
    }, [recipeId]);

    // Handle the click on the edit button
    const handleEditClick = () => {
        setIsEditing(true);
    };

    // Handle visibility change
    const handleVisibilityChange = (newValue) => {
        setEditedFields((prevFields) => ({
            ...prevFields,
            visibility: newValue,
        }));
    };

    // Send the new editable fields to the API
    const handleSaveClick = async () => {
        try {
            const updatedRecipeData = {
                name: editedFields.name,
                price: parseFloat(editedFields.price) || 0,
                barcode: editedFields.barcode,
                partId: parseInt(editedFields.partId) || 1, // Valeur par défaut
                visibility: editedFields.visibility,
                description: editedFields.description,
                steps: editedFields.steps,
                prep_time: editedFields.prep_time || 0 // Valeur par défaut
            };

            console.log("Données envoyées :", updatedRecipeData);

            await updateRecipeById(recipeId, updatedRecipeData);
            setRecipe((prevRecipe) => ({
                ...prevRecipe,
                ...updatedRecipeData,
            }));

            setIsEditing(false);
            toast.success("Recette mise à jour avec succès !");
        } catch (error) {
            console.error("Erreur lors de la sauvegarde de la recette :", error);
        }
    };

    const imageUrl = useMemo(() => {
        const filteredPictures = pictures.filter(pic => pic.recipe_id === recipeId);
        return filteredPictures.length > 0 ? `${process.env.REACT_APP_API_URL}${filteredPictures[0].path}` : undefined;
    }, [recipeId, pictures]);

    if (!recipe) {
        return <div>Chargement de votre recette...</div>;
    }

    return (
        <section className="recipes-details container">
            <Helmet>
                <title>{recipe.name}</title>
            </Helmet>
            <RecipeHeader recipe={recipe} isEditing={isEditing} nutriFacts={nutriFacts} onEditClick={handleEditClick} />
            {isEditing && (
                <RecipeNameForm
                    recipe={recipe}
                    editedFields={editedFields}
                    onSaveClick={handleSaveClick}
                />
            )}
            <RecipeImage recipe={recipe} pictures={pictures} recipeId={recipeId} />
            <RecipeDetailsContainer 
                recipe={recipe} 
                handleVisibilityChange={handleVisibilityChange}// Nouvelle prop
                partIdLabels={partIdLabels}// Nouvelle prop
            />
        </section>
    );
}

export default RecipesDetails;
