// MUI
import { useState, useEffect } from "react";
import { FormControl, InputLabel, Select, MenuItem, Button, TextField } from "@mui/material";
import Textarea from '@mui/joy/Textarea';
import Switch from '@mui/material/Switch';
// Icons
import { CiEdit } from "react-icons/ci";

// API
import { fetchSubscription } from "../../../api/subsciptionAPI"; // Assurez-vous que ce chemin correspond à votre organisation de fichiers

function RecipesDetailsContainer({
    recipe,
    isEditing,
    editingField,
    editedFields,
    handleEditClick,
    handleSaveClick,
    handleInputChange,
    handleVisibilityChange,
    partIdLabels,
}) {
    const [currentUser, setCurrentUser] = useState(null);
    const [subscriptionData, setSubscriptionData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [checked, setChecked] = useState(recipe?.visibility || false);

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user?.id) setCurrentUser(user.id);
    }, []);

    useEffect(() => {
        const getSubscriptionData = async () => {
            if (currentUser && subscriptionData === null && !isLoading) {
                setIsLoading(true);
                try {
                    const data = await fetchSubscription(currentUser);
                    setSubscriptionData(data || {});
                } catch (error) {
                    console.error("Erreur lors de la récupération de l'abonnement :", error);
                } finally {
                    setIsLoading(false);
                }
            }
        };

        getSubscriptionData();
    }, [currentUser, isLoading]); // On ne dépend plus de `subscriptionData` pour éviter de re-déclencher

    // Mettre à jour l'état "checked" lorsque la recette change
    useEffect(() => {
        if (recipe) {
            setChecked(recipe.visibility);
        }
    }, [recipe]);

    // Gérer le basculement de la visibilité
    const handleToggle = () => {
        const newValue = !checked;
        setChecked(newValue);
        handleVisibilityChange(newValue);
    };

    // Vérifier si l'utilisateur est le propriétaire
    const isOwner = currentUser === recipe?.user_id;

    const renderEditField = (label, field, value, type = "text") => (
        <>
            <FormControl fullWidth sx={{ m: 1 }} variant="filled">
                {type === "textarea" ? (
                    <Textarea
                        label={`Editer ${label}`}
                        color="success"
                        multiline
                        rows={4}
                        value={value}
                        onChange={(e) => handleInputChange(field, e.target.value)}
                        required
                        sx={{ mb: 1 }}
                    />
                ) : (
                    <TextField
                        label={`Editer ${label}`}
                        color="success"
                        type={type}
                        value={value}
                        onChange={(e) => handleInputChange(field, e.target.value)}
                    />
                )}
            </FormControl>
            <Button onClick={handleSaveClick} variant="text" color="success">Sauvegarder</Button>
        </>
    );

    if (!recipe) {
        return <div>La recette n'est pas définie</div>;
    }

    return (
        <div className="recipes-details-text">
            <div className="recipes-details-price-container">
                <div className="recipes-details-price">
                    <div className="recipes-details-price-title">
                        <h2>Prix</h2>
                        {isOwner && <button onClick={() => handleEditClick("price")} className="edit-btn"><CiEdit className="edit-icon" size={15} /></button>}
                    </div>
                    {isEditing && editingField === "price"
                        ? renderEditField("prix", "price", editedFields.price, "number")
                        : <div className="recipes-details-price">{recipe.price} €</div>}
                </div>

                <div className="recipes-details-barcode">
                    <div className="recipes-details-barcode-title">
                        <h2>Code Barre</h2>
                        {isOwner && <button onClick={() => handleEditClick("barcode")} className="edit-btn"><CiEdit className="edit-icon" size={15} /></button>}
                    </div>
                    {isEditing && editingField === "barcode"
                        ? renderEditField("code-barres", "barcode", editedFields.barcode)
                        : <div className="recipes-details-barcode">{recipe.barcode}</div>}
                </div>
            </div>

            <div className="recipes-details-description-container">
                <div className="recipes-details-description">
                    <div className="recipes-details-description-title">
                        <h2>Description</h2>
                        {isOwner && <button onClick={() => handleEditClick("description")} className="edit-btn"><CiEdit className="edit-icon" size={15} /></button>}
                    </div>
                    {isEditing && editingField === "description"
                        ? renderEditField("description", "description", editedFields.description, "textarea")
                        : <div className="recipes-details-description"><p>{recipe.description}</p></div>}
                </div>

                <div className="recipes-details-prep_time">
                    <div className="recipes-details-prep_time-title">
                        <h2>Temps de préparation en minutes</h2>
                        {isOwner && <button onClick={() => handleEditClick("prep_time")} className="edit-btn"><CiEdit className="edit-icon" size={15} /></button>}
                    </div>
                    {isEditing && editingField === "prep_time"
                        ? renderEditField("temps de préparation", "prep_time", editedFields.prep_time)
                        : <div className="recipes-details-prep_time">{recipe.prep_time}</div>}
                </div>

                <div className="recipes-details-steps">
                    <div className="recipes-details-steps-title">
                        <h2>Procédé step-by-step</h2>
                        {isOwner && <button onClick={() => handleEditClick("steps")} className="edit-btn"><CiEdit className="edit-icon" size={15} /></button>}
                    </div>
                    {isEditing && editingField === "steps"
                        ? renderEditField("étapes", "steps", editedFields.steps, "textarea")
                        : <div className="recipes-details-steps"><p>{recipe.steps}</p></div>}
                </div>

                <div className="recipes-details-partid-container">
                    <div className="recipes-details-partid">
                        <div className="recipes-details-partid-title">
                            <h2>Type de recette</h2>
                            {isOwner && <button onClick={() => handleEditClick("partId")} className="edit-btn"><CiEdit className="edit-icon" size={15} /></button>}
                        </div>
                        {isEditing && editingField === "partId" ? (
                            <>
                                <FormControl fullWidth sx={{ m: 1 }} variant="filled">
                                    <InputLabel id="partId-label" color="success">Editer le nombre de parts</InputLabel>
                                    <Select
                                        labelId="partId-label"
                                        id="partId-select"
                                        value={editedFields.partId}
                                        color="success"
                                        onChange={(e) => handleInputChange("partId", e.target.value)}
                                    >
                                        {Object.entries(partIdLabels).map(([key, value]) => (
                                            <MenuItem key={key} value={key}>{value}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <Button onClick={handleSaveClick} variant="text" color="success">Sauvegarder</Button>
                            </>
                        ) : (
                            <div className="recipes-details-partid">
                                {partIdLabels[recipe.partId] ? partIdLabels[recipe.partId].split("—")[1] : "Part ID not found"}
                            </div>
                        )}
                    </div>

                    <div className="recipes-details-visibility">
                        <div className="recipes-details-visibility-title">
                            <h2>Visibilité</h2>
                            {isOwner && <button onClick={() => handleEditClick("visibility")} className="edit-btn"><CiEdit className="edit-icon" size={15} /></button>}
                        </div>
                        {isEditing && editingField === "visibility" ? (
                            <Switch
                                checked={checked}
                                onChange={handleToggle}
                                className="edit-btn"
                                color="success"
                                inputProps={{ 'aria-label': 'toggle visibility' }}
                            />
                        ) : (
                            <div className="recipes-details-visibility">{recipe.visibility ? "Visible" : "Invisible"}</div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default RecipesDetailsContainer;
