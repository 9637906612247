import { useState, useEffect } from "react";
import { fetchSubscription } from "../../api/subsciptionAPI"; 
import { Link } from 'react-router-dom'; 
import "../../views/client-dashboard/ClientDashboard.css";

// Formater la date
const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${day < 10 ? '0' + day : day}/${month < 10 ? '0' + month : month}/${year}`;
};

function ClientSubscription({ userInfo }) {
    const [subscriptionResponse, setSubscriptionResponse] = useState(null);
    const [loading, setLoading] = useState(true); 

    // Récupérer l'abonnement utilisateur au chargement du composant
    useEffect(() => {
        const fetchUserSubscription = async () => {
            try {
                const response = await fetchSubscription(userInfo.id); 

                if (response) {
                    setSubscriptionResponse(response);
                } else {
                    setSubscriptionResponse({ status: 200, data: null });
                }
            } catch (error) {
                console.error("Erreur lors de la récupération de l'abonnement :", error);
                setSubscriptionResponse({ status: 500, message: "Erreur lors de la récupération de l'abonnement." });
            } finally {
                setLoading(false); 
            }
        };

        if (userInfo && userInfo.id) {
            fetchUserSubscription(); 
        }
    }, [userInfo]);

    // Affichage pendant le chargement des données
    if (loading) {
        return (
            <section className='client-subscription'>
                <div className='flex-center'>
                    <h2 className="client-subscription-title">Chargement...</h2>
                </div>
            </section>
        );
    }

    // Vérifie si le fetch a échoué (par exemple, problème réseau)
    if (subscriptionResponse?.status !== 200) {
        return (
            <section className='client-subscription'>
                <div className='flex-center'>
                    <h2 className="client-subscription-title">
                        Une erreur s'est produite lors de la récupération des informations. Veuillez réessayer plus tard.
                    </h2>
                </div>
                <div className="flex-center">
                    <Link to="/help">
                        <button className="btn-full">Obtenir de l'aide</button>
                    </Link>
                </div>
            </section>
        );
    }

    // Vérifie si le statut est 200 mais qu'il n'y a pas d'abonnement (data null)
    if (subscriptionResponse?.status === 200 && !subscriptionResponse.data) {
        return (
            <section className='client-subscription'>
                <div className='flex-center'>
                    <h2 className="client-subscription-title">
                        Aucun abonnement trouvé. Vous pouvez choisir une formule ci-dessous.
                    </h2>
                </div>
                {/* Intégration de la table de prix Stripe */}
                <div className='stripe-pricing'>
                    <stripe-pricing-table
                        pricing-table-id="prctbl_1PGLNoLn6islyFl10iHKSc58"
                        publishable-key="pk_live_E27q31wJqPtkHNEoQzSJya8200AoUbm2JJ"
                        client-reference-id={userInfo ? userInfo.id : ''}
                        customer-email={userInfo ? userInfo.email : ''}
                    >
                    </stripe-pricing-table>
                </div>
            </section>
        );
    }

    // Si un abonnement est trouvé, afficher les informations d'abonnement + option de gestion
    if (subscriptionResponse?.status === 200 && subscriptionResponse.data) {
        const subscriptionInfos = subscriptionResponse.data;

        return (
            <section className='client-manage-subscription'>
                <div className='flex-center'>
                    <h2 className='client-manage-subscription-title'>Votre abonnement Menu Maker</h2>
                </div>
                <div className='flex-center'>
                    <p className='client-manage-subscription-description'>
                        Vous êtes membre premium Mamossa depuis le {formatDate(subscriptionInfos?.createdAt)}. 
                        Vous souscrivez à l'abonnement {subscriptionInfos?.subscription_type === "menu_maker" ? "Menu Maker" : subscriptionInfos?.subscription_type}.
                    </p>
                </div>
                <div className='flex-center'>
                    <a href="https://billing.stripe.com/p/login/14k18a8FB82f0wM6oo" target="_blank" rel="noopener noreferrer">
                        <button className='btn-full'>Gérer mon abonnement</button>
                    </a>
                </div>
            </section>
        );
    }

    return null;
}

export default ClientSubscription;
